export const baseConfig = [
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 1,
    fixed: false, // 固定列的位置(left, right)
    width: 120, // 默认宽度，像素
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 2,
    fixed: false, // 固定列的位置(left, right)
    width: 120, // 默认宽度，像素
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 3,
    fixed: false, // 固定列的位置(left, right)
    width: 120, // 默认宽度，像素
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 4,
    fixed: false, // 固定列的位置(left, right)
    width: 120, // 默认宽度，像素
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 5,
    fixed: false, // 固定列的位置(left, right)
    width: false, // 默认宽度，像素
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 6,
    fixed: false, // 固定列的位置(left, right)
    width: false,
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 7,
    fixed: false, // 固定列的位置(left, right)
    width: false,
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 8,
    fixed: false, // 固定列的位置(left, right)
    width: false,
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    monthIndex: 9,
    fixed: false, // 固定列的位置(left, right)
    width: 120,
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 120,
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 120,
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 120,
    inputFlag: false
  },
  {
    key: '',
    name: '',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: false,
    inputFlag: false
  }
]
