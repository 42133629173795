<template>
  <div>
    <PocCardTable>
      <template v-slot:headerTitle>
        <span class="pcth-title-left">PC Tracker / 详情</span>
        <span v-html="'&nbsp;'"></span>
        <span style="color: #303133; font-weight: 500" class="pcth-title-left"
          >/ 调整</span
        ></template
      >
      <template v-slot:headerGroup>
        <el-button @click="cancle">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </template>
      <div class="adjuestment-list-page">
        <div class="scrollContainer">
          <el-descriptions
            title="Basic Info"
            :column="4"
            labelClassName="basic-descriptions"
          >
            <el-descriptions-item label="HC Nature">{{
              selectEditMsg.hcNature
            }}</el-descriptions-item>
            <el-descriptions-item label="HFM Code">{{
              selectEditMsg.hfmCode
            }}</el-descriptions-item>
            <el-descriptions-item label="Business Unit">{{
              selectEditMsg.businessUnitName
            }}</el-descriptions-item>
            <el-descriptions-item label="Team">{{
              selectEditMsg.team
            }}</el-descriptions-item>
            <el-descriptions-item label="Chinese Name">{{
              selectEditMsg.chineseName
            }}</el-descriptions-item>
            <el-descriptions-item label="English Name">{{
              selectEditMsg.englishName
            }}</el-descriptions-item>
            <el-descriptions-item label="Position">{{
              selectEditMsg.position
            }}</el-descriptions-item>
            <el-descriptions-item label="Start Date">{{
              selectEditMsg.startDate
            }}</el-descriptions-item>
          </el-descriptions>
          <div
            class="editTableBox1"
            v-for="(table, tableIndex) in tables"
            :key="'table' + tableIndex"
          >
            <div class="editTableTitle">{{ tableName[tableIndex] || '-' }}</div>
            <PocTable
              :data="table"
              size="large"
              hidepage
              highlight-current-row
              :current-page.sync="pageConfig.pageNum"
              :total="pageConfig.total"
              @size-change="pageSizeChange"
              @page-current-change="pageChange"
              :max-height="maxHeight"
            >
              <!-- UI规定左对齐 表头第一列 左边距20px -->
              <el-table-column
                :cell-style="{ 'text-align': 'center' }"
                style="text-align: center"
                width="20"
              >
                <template slot="header">
                  <span></span>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, i) in MonthArr"
                :key="`table-${tableIndex}-${item}-${nowYear}`"
                :prop="`${item}-${nowYear}`"
                :label="`${item}-${nowYear}`"
                align="left"
                width="100"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row[`${item}-${nowYear}`]"
                    type="number"
                    @input="$event?scope.row[`${item}-${nowYear}`] = $event:scope.row[`${item}-${nowYear}`] = 0"
                    @change="sumTotal(table)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="total"
                label="totalCost"
                align="center"
                width="150"
              >
              </el-table-column>
            </PocTable>
          </div>
        </div>
      </div>
    </PocCardTable>
  </div>
</template>
<script>
import { baseConfig } from '../../editionData/adjustData'

export default {
  mixins: [$PCommon.TableMixin],
  name: 'adjustTable',
  components: {},
  props: {
    fundsAddForm: {
      type: Object
    },
    addFlag: {
      type: Object
    },
    chooseTab: {
      type: Number
    }
  },

  data() {
    const h = document.getElementsByTagName('body')[0].clientHeight - 360 + 'px'
    let rules = {}
    const rule = [{ required: true, message: '', trigger: ['blur'] }]
    return {
      rules,
      maxHeight: h,
      basePayrollConfig: baseConfig,
      thirteenTableConfig: '',
      holidayTableConfig: '',
      salesCommConfig: '',
      SocialChargesConfig: '',
      MedicalInsuranceConfig: '',
      CommInsurancConfig: '',
      HomeLeaveConfig: '',
      HousingConfig: '',
      RelocationOpConfig: '',
      FillingfeeConfig: '',
      SeveranceConfig: '',
      bonusOp162Op164Config: '',

      MonthArr: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      selectEditMsg: {}, // 详情信息
      tableData: [],
      basePayTableData: [],
      thirteenTableData: [],
      HolidayTableData: [],
      salesCommTableData: [],
      socialChargesBaseTableData: [],
      medicalInsuranceTableData: [],
      commInsuranceTableData: [],
      housingSchoolFeeOthersTableData: [],
      relocationTableData: [],
      homeLeaveTableData: [],
      fellingfeeOthersTableData: [],
      severanceTableData: [],
      bonusOpTableData: [],
      hcObj: {},
      props: { multiple: true },
      pageConfig: {
        pageNum: 1,
        pageSize: 10
      },
      tables: [],
      tablesFl: [
        'basePayrollOp111',
        'thirteenPayrollOp111',
        'holidayPayOp112',
        'salesCommOp113',
        'socialChargesOp121',
        'medicalInsuranceOp126',
        'commInsuranceIitOp126',
        'homeLeaveOp132',
        'housingSchoolFeeOthersOp132',
        'relocationOp132',
        'fellingfeeOthersOp132',
        'severanceOp141',
        'bonusOp162Op164'
        // 'hc',
        // 'housingFundBase',
      ],
      tableName: [
        'Base Payroll-OP111',
        '13th Payroll-OP111',
        'Holiday Pay-OP112',
        'Sales Comm-OP113',
        'Social Charges-OP121',
        'Medical Insurance-OP126',
        'Comm. Insuranc IIT-OP126',
        'Home leave-OP132.01',
        'Housing / School Fee / others-OP132.02',
        'Relocation-OP132.05',
        'Filling fee&Others-OP132.03/04/06',
        'Severance-OP141',
        'Bonus-OP162/OP164'
      ],
      nowYear: new Date().getFullYear().toString().substring(2),
      tableColumn: [],
      indexMonth: new Date().getMonth() + 1
    }
  },
  watch: {},
  created() {
    console.log('888888', this.$route.query.op111PageConfig)
    this.rowId = this.$route.query.id
    this.indexMonth = this.$route.query.indexMonth
    console.log(this.indexMonth)
    this.tableColumn = []
    this.tables = []
    this.getOp111Detail()
  },
  methods: {
    // 实时计算total
    sumTotal(table){
      let total = 0
      for (let key in table[0]) {
        if ( table[0].hasOwnProperty(key)) {
          if(!['total','undefined'].includes(key)){
            total = total + Number(table[0][key])
            console.log(key,  table[0][key]);
          }
        }
      }
      table[0].total = Number(total).toFixed(2)
      console.log(table);
    },
    getData() {
      return { tableData: this.tableData, columnConfig: this.columnConfig }
    },
    checkAmount(e) {
      e.target.value =
        e.target.value.match(/^[0-9,]*(\.?[0-9]{0,2})/)[0] || null
      this.text = e.target.value
    },
    async getOp111Detail() {
      // 获取明细列表
      const { data, code } = await this.$Api.salary.getOp111Detail({
        id: this.rowId
      })
      this.queryLoading = false
      if (code === 200) {
        //copy进来，接口获取的值要可编辑，input框显现
        this.selectEditMsg = data
        console.log(data.pctrackerOp111Info)
        for (let i in data.pctrackerOp111Info) {
          let name = i.substring(0, i.length - 3)
          let m = i.substring(i.length - 3)
          let index = this.tablesFl.indexOf(name)
          if (index > -1) {
            if (!this.tables[index]) {
              let obj = {}
              obj[`${m}-${this.nowYear}`] = data.pctrackerOp111Info[i]
              this.tables[index] = [obj]
            } else {
              this.tables[index][0][`${m}-${this.nowYear}`] =
                data.pctrackerOp111Info[i]
            }
          }
        }
        console.log(this.tables)
        this.tablesFl.map((v, index) => {
          this.tables[index][0].total = data[`${v}Total`]
        })
        this.$forceUpdate()
        // alert(1)
      }
    },
    async save() {
      //根据后端接口需要、将13个表格合并成一个json
      // var allData = {
      //   ...this.basePayTableData[0],
      //   ...this.thirteenTableData[0],
      //   ...this.HolidayTableData[0],
      //   ...this.salesCommTableData[0],
      //   ...this.socialChargesBaseTableData[0],
      //   ...this.medicalInsuranceTableData[0],
      //   ...this.commInsuranceTableData[0],
      //   ...this.housingSchoolFeeOthersTableData[0],
      //   ...this.relocationTableData[0],
      //   ...this.homeLeaveTableData[0],
      //   ...this.fellingfeeOthersTableData[0],
      //   ...this.severanceTableData[0],
      //   ...this.bonusOpTableData[0],
      //   ...this.hcObj
      // }
      let allData = {}
      this.tables.map((v, index) => {
        for (let i in v[0]) {
          let key = this.tablesFl[index] + i.split('-')[0]
          allData[key] = v[0][i]
        }
      })
      console.log(allData)
      // let allData = {...this.selectEditMsg.pctrackerOp111Info,...res}

      allData['id'] = this.selectEditMsg.pctrackerOp111Info.id
      allData['versionId'] = this.selectEditMsg.pctrackerOp111Info.versionId
      allData['staffId'] = this.selectEditMsg.pctrackerOp111Info.staffId

      const { data, code } = await this.$Api.salary.editNowOp111Detail({
        ...allData
      })
      this.queryLoading = false
      if (code === 200) {
        this.$message({
          type: 'success',
          message: '调整成功'
        })
        this.getOp111Detail()
        let query = this.$route.query.params
        query.jumpPageConfig = this.$route.query.op111PageConfig
        query.buttonActive = this.$route.query.buttonActive
        //返回二级页
        // await this.$router.replace({
        //   path: '/home/pcTracker/secondLevelPage',
        //   query: query
        // })
      }
    },
    cancle() {
      //返回上一级
      let query = this.$route.query.params
      // query.jumpPageConfig = this.$route.query.op111PageConfig
      query.buttonActive = this.$route.query.buttonActive
      //返回二级页
      this.$router.replace({
        path: '/home/pcTracker/secondLevelPage',
        query: query
      })
    },
    checkData() {
      for (let i = 0; i < this.tableData.length; i++) {
        let v = this.tableData[i]
        if (v.rowform && Object.values(v).some((e) => e == '')) {
          //是新增行、复制的行
          return false
        }
      }
      return true
    },
    resizeHandle() {
      this.maxHeight =
        document.getElementsByTagName('body')[0].clientHeight - 430 + 'px'
    }
  },
  mounted() {
    this.resizeHandle()
    window.addEventListener('resize', this.resizeHandle)
  }
}
</script>
<style lang="scss" scoped>
.adjuestment-list-page {
  .editTableBox1 {
    margin-top: 20px;
    .editTableTitle {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }
  margin-bottom: $MP32;
  .el-table .cell {
    padding: 0;
    line-height: $MP24;
  }
  .require-color {
    color: $--color-require-text;
  }
  .err {
    ::v-deep .el-input__inner {
      border-color: $--color-error;
    }
  }
  .require-item::before {
    content: '*';
    color: $--color-require-text;
    margin-right: $MP4;
  }

  ::v-deep .el-table__body-wrapper {
    padding-bottom: $MP16;
  }
  ::v-deep .el-table .cell {
    padding: 0 $MP2;
  }
}
.w100 {
  width: 100%;
}
.pr24 {
  padding-right: 24px;
  box-sizing: border-box;
}
.rowItem {
  margin-top: 12px;
}
.scrollContainer {
  //height: calc(100vh - 200px) !important ;
  overflow: auto;
  //padding-bottom: 20px;
  box-sizing: border-box;
}
// .inductionTitleLab {
//   width: 100%;
//   border-bottom: 1px solid #828282;
//   margin-bottom: 10px;
// }
.inductionTitleMsg {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  padding: 20px 0 2px 0px;
  box-sizing: border-box;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexCenter {
  justify-content: center;
  align-items: center;
}
.AlCenter {
  align-items: center;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.upContainer {
  width: 90px;
  margin-top: 7px;
  justify-content: flex-end;
}
.flex {
  display: flex;
}
.flexBetw {
  display: flex;
  justify-content: space-between;
}
.el-range-editor {
  width: 100%;
}
.buttonActive {
  width: 132px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 15px;
}

.lableDetail {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 176px;
}
.lableDetail2 {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  // border:1px solid red;
  width: 300px;
}
.lableDetail3 {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  width: 256px;
}
.lableDetail4 {
  width: 400px;
}
.lableContent {
  width: 180px;
  color: #323435;
  font-size: 14px;
  margin-left: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lableContentSmall {
  width: 50px;
}
.lableContentnone {
  width: 100%;
}
.lableContent span {
  color: #545454;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.buttonBottom {
  justify-content: flex-end;
  margin-right: 150px;
  margin-top: 20px;
  .el-button--mini {
    margin-right: 50px;
  }
}
.pcth-title-left {
  height: 29px;
  font-size: 21px;
  font-weight: 400;
  color: #868686;
  line-height: 29px;
}
::v-deep .el-card__body {
  padding: 20px;
}
::v-deep .el-card__header {
  padding: 20px;
}
::v-deep .el-descriptions__title {
  font-weight: 200;
}
::v-deep
  .el-descriptions
  .el-descriptions__body
  .el-descriptions__table
  .el-descriptions-row
  .el-descriptions-item
  .el-descriptions-item__label {
  color: #868686;
}
::v-deep
  .el-descriptions
  .el-descriptions__body
  .el-descriptions__table
  .el-descriptions-row
  .el-descriptions-item
  .el-descriptions-item__content {
  color: #323435;
}
::v-deep .el-card__header {
  padding: 20px 20px 16px 20px;
}
</style>
<style lang="scss">
.adjuestment-list-page {
  .el-icon-minus:before {
    content: '';
    transform: rotate(180deg);
  }
  .el-icon-plus:before {
    content: '';
  }

  .el-card__body {
    padding: 0 10px 20px 10px !important;
    box-sizing: border-box;
  }
}
</style>
